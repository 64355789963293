import axios from 'axios'

/**
 * 针对没带token 的请求
 * @type {AxiosInstance}
 */

const request = axios.create({
    timeout: 5000,
    // baseURL: 'http://127.0.0.1:8999',
    baseURL: 'http://47.101.141.211:8999/',
});

// 只取data
request.interceptors.response.use(result => {
    return result.data;
})
// 拦截头看看有没有token
request.interceptors.request.use(
    req => {
        if (window.sessionStorage.getItem("token")) {
            req.headers['token'] = window.sessionStorage.getItem("token")
        }
        return req
    }
)
export default request;
