import axios  from "axios";

/**
 * 针对带有token 的请求的封装
 */

const request = axios.create({
    timeout: 5000,
    // baseURL: 'http://127.0.0.1:8888/system',
    baseURL: 'https://api.caiqichang.cn/system',
    // headers:{
    //     token: window.sessionStorage.getItem('token')
    // },
})

// 只取data
request.interceptors.response.use(result => {
    return result.data;
})
// 拦截头看看有没有token
request.interceptors.request.use(
    req => {
        if (window.sessionStorage.getItem("token")) {
            req.headers['token'] = window.sessionStorage.getItem("token")
        }
        return req
    }
)


export default request;
