<template>
    <h1>22222222</h1>
</template>

<script>
    export default {
        name: "mainTest2"
    }
</script>

<style scoped>

</style>
