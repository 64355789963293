import request from "./tokenRequest";

/**
 * 首页图片关系相关的api
 */
export default {
    getQiNiuToken(){
        return request.get(`/qiniu/getUpLoadToken`);
    },
    getIndexImg(){
        return request.get(`/indexImg/findIndexImg`)
    },
    updateIndexImg(img){
        return request({
            method: 'post',
            url:`/indexImg/updateImg`,
            data:{
                imgUrls: img
            }
        })
    }
}