import request from './tokenRequest';

/**
 * 公告相关的api
 */
export default  {
     getAllNotice(page){
         return request({
             method: 'get',
             url: '/notice/getAllNotice?page='+page,
         })
    },
    allNotice({content, title, imgUrl, openid, avatar, username}){
         return request({
             url: '/notice/addNotice',
             method:'post',
             data:{
                 noticeContent:content,
                 createAuthor:username,
                 avatar:avatar,
                 openid:openid,
                 noticeTitle:title,
                 noticeImg:imgUrl
             }
         })
    }
}