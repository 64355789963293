<template>
    <div>
        <el-button type="primary" @click="publicActiveOpen">新建</el-button>


        <!--    添加活动-->
        <el-dialog title="新建活动" :visible.sync="publicActive" width="750px">
            <el-form :model="publicActiveForm" :rules="publicActiveRules" ref="publicActiveForm" autocomplete="off">
                <!--        <el-form-item label="头像" label-width="200px" prop="phoneNumber">-->
                <!--          &lt;!&ndash;              <el-input v-model="updateInfoForm.phoneNumber"&ndash;&gt;-->
                <!--          &lt;!&ndash;                        style="width: 300px"></el-input>&ndash;&gt;-->
                <!--          <el-upload-->
                <!--              class="avatar-uploader"-->
                <!--              action="http://upload-z2.qiniup.com"-->
                <!--              :show-file-list="false"-->
                <!--              :data="uploadData"-->
                <!--              :on-success="handleAvatarSuccess"-->
                <!--              :before-upload="beforeAvatarUpload">-->
                <!--            <img v-if="publicActiveForm.avatar" :src="publicActiveForm.avatar" class="avatar">-->
                <!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
                <!--          </el-upload>-->
                <!--        </el-form-item>-->
                <el-form-item label="活动图片列表">
                    <el-upload
                            class="upload-demo"
                            action="https://upload-z2.qiniup.com"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :data="uploadData"
                            :file-list="fileList"
                            list-type="picture">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="活动标题" label-width="200px" prop="activeTitle">
                    <el-input v-model="publicActiveForm.activeTitle"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动标签" label-width="200px" prop="tags">
                    <el-input v-model="publicActiveForm.tags"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动时间" label-width="200px" prop="activeTime">
                    <el-input v-model="publicActiveForm.activeTime" placeholder="格式：2022-05-20 12:00:00"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动人数" label-width="200px" prop="canJoinNumber">
                    <el-input v-model="publicActiveForm.canJoinNumber"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动地址" label-width="200px" prop="activeAddress">
                    <el-input v-model="publicActiveForm.activeAddress"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动内容" label-width="200px" prop="activeContent">
                    <el-input type="textarea" :rows="5" v-model="publicActiveForm.activeContent"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="联系手机号码" label-width="200px" prop="contactPhone">
                    <el-input v-model="publicActiveForm.contactPhone"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="联系邮箱" label-width="200px" prop="contactMail">
                    <el-input v-model="publicActiveForm.contactMail"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="联系地址" label-width="200px" prop="contactAddress">
                    <el-input v-model="publicActiveForm.contactAddress"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label-width="200px">
                    <el-button @click="publicActive=false">取消</el-button>
                    <el-button @click="publicActiveFormSubmit" type="primary">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


        <el-table
                :data="activeList"
                stripe
                highlight-current-row
                style="width: 100%">
            <el-table-column
                    prop="activeTitle"
                    label="活动标题"
                    width="150">
            </el-table-column>
            <el-table-column
                    prop="activeTime"
                    label="活动时间"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="publicTime"
                    label="发布时间"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="activeAddress"
                    label="活动地址"
                    width="230">
            </el-table-column>
            <el-table-column
                    prop="lookNumber"
                    label="被查看次数"
                    width="100">
            </el-table-column>
          <el-table-column
              prop="canJoinNumber"
              label="可参加人数"
              width="100">
          </el-table-column>
            <el-table-column
                    label="已参加人数"
                    width="100">
              <template slot-scope="scope">
                {{scope.row.joinNumber}}
              </template>
            </el-table-column>
            <el-table-column
                    label="操作"
                    width="180">
                <template slot-scope="scope">
                    <el-button type="primary" @click="activeMoreInfo(scope.row)">详情</el-button>
                    <el-button type="danger" @click="deleteActive(scope.row)">删除</el-button>
                    <!--                <el-button type="primary" @click="GetUserInfoByOpenid(scope.row)">修改</el-button>-->
                </template>
            </el-table-column>
            <!--      <el-table-column-->
            <!--          prop="username"-->
            <!--          label="姓名"-->
            <!--          width="180">-->
            <!--      </el-table-column>-->
            <!--      <el-table-column-->
            <!--          prop="openid"-->
            <!--          label="openid">-->
            <!--      </el-table-column>-->
            <!--      <el-table-column-->
            <!--          prop="remark"-->
            <!--          label="签名">-->
            <!--      </el-table-column>-->
            <!--      <el-table-column-->
            <!--          prop="sex"-->
            <!--          label="性别 ">-->
            <!--      </el-table-column>-->
            <!--      <el-table-column-->
            <!--          prop="role"-->
            <!--          label="角色">-->
            <!--      </el-table-column>-->
            <!--      <el-table-column-->
            <!--          label="操作">-->
            <!--        <template slot-scope="scope">-->
            <!--          <el-button type="primary" @click="GetUserInfoByOpenid(scope.row)">修改</el-button>-->
            <!--        </template>-->
            <!--      </el-table-column>-->
        </el-table>
        <!--  分页-->
        <div class="page-el">
            <el-pagination
                    background
                    @current-change="currentChange"
                    layout="total,prev, pager, next"
                    :total="total"
                    :page-size="pageSize"
            >
            </el-pagination>
        </div>
        <!--    diago-->
        <el-dialog
                title="修改用户信息"
                :visible.sync="this.dialogVisible"
                width="30%"
                :before-close="cancel">
            <!--      -->
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="姓名">
                    <el-input v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="签名">
                    <el-input v-model="form.remark"></el-input>
                </el-form-item>
                <el-form-item label="手机">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
            </el-form>
            <!--      -->
            <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
    <el-button type="primary" @click="toEditUser">确 定</el-button>
  </span>
        </el-dialog>

        <!--    修改活动-->
        <el-dialog title="活动详细" :visible.sync="activeMore" width="1000px">
            <el-form :model="activeEntity" autocomplete="off">
                <!--        <el-form-item label="头像" label-width="200px" prop="phoneNumber">-->
                <!--          &lt;!&ndash;              <el-input v-model="updateInfoForm.phoneNumber"&ndash;&gt;-->
                <!--          &lt;!&ndash;                        style="width: 300px"></el-input>&ndash;&gt;-->
                <!--          <el-upload-->
                <!--              class="avatar-uploader"-->
                <!--              action="http://upload-z2.qiniup.com"-->
                <!--              :show-file-list="false"-->
                <!--              :data="uploadData"-->
                <!--              :on-success="handleAvatarSuccess"-->
                <!--              :before-upload="beforeAvatarUpload">-->
                <!--            <img v-if="publicActiveForm.avatar" :src="publicActiveForm.avatar" class="avatar">-->
                <!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
                <!--          </el-upload>-->
                <!--        </el-form-item>-->
                <el-form-item label="活动图片列表">
                    <!--          <el-upload-->
                    <!--              class="upload-demo"-->
                    <!--              action="http://upload-z2.qiniup.com"-->
                    <!--              :on-success="handleAvatarSuccess"-->
                    <!--              :before-upload="beforeAvatarUpload"-->
                    <!--              :on-preview="handlePreview"-->
                    <!--              :on-remove="handleRemove"-->
                    <!--              :data="uploadData"-->
                    <!--              :file-list="activeEntityImages"-->
                    <!--              list-type="picture">-->
                    <!--            <el-button size="small" type="primary">点击上传</el-button>-->
                    <!--            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>-->
                    <!--          </el-upload>-->
                    <el-image v-for="item in activeEntityImages"
                              style="width: 220px; height: 150px;margin: 3px"
                              :src="item.url"
                              :preview-src-list="activeEntityImagesBig">
                    </el-image>
                </el-form-item>
                <el-form-item label="活动标题" label-width="200px">
                    <el-input v-model="activeEntity.activeTitle"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动标签" label-width="200px">
                    <el-input v-model="activeEntity.tags"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动时间" label-width="200px">
                    <el-input v-model="activeEntity.activeTime" placeholder="格式：2022-05-20 12:00:00"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动人数" label-width="200px">
                    <el-input v-model="activeEntity.canJoinNumber"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动地址" label-width="200px">
                    <el-input v-model="activeEntity.activeAddress"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="活动内容" label-width="200px">
                    <el-input type="textarea" :rows="5" v-model="activeEntity.activeContent"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="联系手机号码" label-width="200px">
                    <el-input v-model="activeEntity.contactPhone"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="联系邮箱" label-width="200px">
                    <el-input v-model="activeEntity.contactMail"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="联系地址" label-width="200px">
                    <el-input v-model="activeEntity.contactAddress"
                              style="width: 400px"></el-input>
                </el-form-item>
                <el-button type="primary" @click="downloadExcel">导出</el-button>
                <!--                <el-button type="primary" @click="">导出</el-button>-->
                <el-form-item label="参加情况">
                    <el-table :data="joinUserList">
                        <el-table-column
                                label="头像" width="80">
                            <template slot-scope="scope">
                                <el-avatar size="large" :src="scope.row.avatar"></el-avatar>
                            </template>
                        </el-table-column>
                        <el-table-column property="name" label="姓名" width="60"></el-table-column>
                        <el-table-column property="telephone" label="联系电话"></el-table-column>
                        <el-table-column property="mail" label="联系邮箱"></el-table-column>
                        <el-table-column property="address" label="联系地址"></el-table-column>
                        <el-table-column property="time" label="参加时间"></el-table-column>
                        <el-table-column
                                label="操作"
                                width="180">
                            <template slot-scope="scope">
                                <el-button type="danger" @click="deleteJoinUser(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label-width="200px">
                    <el-button @click="activeMore=false">取消</el-button>
                    <el-button @click="activeMoreInfoSubmit" type="primary">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>

</template>

<script>
    import userHttp from "../http/userHttp";
    import tokenRequest from "../http/tokenRequest";
    import axios from "axios";

    export default {
        data() {
            return {
                baseUrl: tokenRequest.defaults.baseURL,
                activeList: [],
                fileList: [],
                publicActive: false,
                uploadData: {
                    token: ''
                },
                publicActiveForm: {
                    activeTitle: '',
                    images: [],
                    tags: '',
                    activeTime: '2022-05-20 12:00:00',
                    activeAddress: '',
                    activeContent: '',
                    contactMail: '',
                    contactPhone: '',
                    contactAddress: '',
                    canJoinNumber: '',
                },
                publicActiveRules: {
                    activeTitle: [{required: true, message: '请填写活动标题'}],
                    tags: [{required: true, message: '请填写标签'}],
                    activeTime: [{required: true, message: '请填写活动时间'}],
                    activeAddress: [{required: true, message: '请填写活动地址'}],
                    activeContent: [{required: true, message: '请填写活动内容'}],
                    contactMail: [{required: true, message: '请填写联系邮箱'}],
                    contactPhone: [{required: true, message: '请填写联系手机号码'}],
                    contactAddress: [{required: true, message: '请填写联系地址'}],
                    canJoinNumber: [{required: true, message: '请填写活动人数'}],
                },
                form: {
                    username: '',
                    remark: '',
                    phone: '',
                    openid: ''
                },
                dialogVisible: false,
                userList: [],
                activeEntity: {
                    images: []
                },
                activeEntityImages: [],
                activeEntityImagesBig: [],
                activeMore: false,
                joinUserList: [],
                pageSize: 5,
                total: 0,
            }
        },
        methods: {

            deleteJoinUser(row) {
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    tokenRequest.post('/api/active/deleteJoinUser?id=' + row.join_id).then(res => {
                        if (res.code === 1) {
                            this.getJoinUserInfo(this.activeEntity.id);
                            this.getActiveList();
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: '操作失败'
                            })

                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            deleteActive(row) {
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    tokenRequest.post('/api/active/delete?id=' + row.id).then(res => {
                        if (res.code === 1) {
                            this.getActiveList();
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: '操作失败'
                            })

                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            downloadExcel() {
                if (this.joinUserList.length>0) {
                    window.open(this.baseUrl +'/api/active/downloadJoinUserInfo?activeId=' + this.activeEntity.id)

                } else {
                    this.$message.error("表单没有数据")
                }
            }
            ,
            activeMoreInfoSubmit() {
                let formData = JSON.parse(JSON.stringify(this.activeEntity))
                tokenRequest.post('/api/active/update', formData).then(res => {
                    console.log(res);
                    if (res.code === 1) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        })
                        this.getTotal()
                        this.activeMore = false;
                    } else {
                        this.$message({
                            message: '发生错误，请检查',
                            type: 'error'
                        })
                    }
                })
            }
            ,
            activeMoreInfo(row) {
                this.getJoinUserInfo(row);
                this.activeEntityImages = [];
                this.activeEntityImagesBig = [];
                tokenRequest.post('/api/active/getActiveImage?id=' + row.id).then(res => {
                    for (let i = 0; i < res.result.length; i++) {
                        let p = {name: '图片' + i + 1 + 'jpg', url: res.result[i].url}
                        this.activeEntityImages.push(p)
                        this.activeEntityImagesBig.push(res.result[i].url)
                    }

                    console.log(this.activeEntityImages);
                })
                this.activeMore = true
                this.activeEntity = row;

            }
            ,
            getJoinUserInfo(row) {
                console.log('getJoinUserInfo');
                this.joinUserList = [];
                tokenRequest.post('/api/active/getJoinUserInfo?activeId=' + row.id).then(res => {
                    this.joinUserList = res.result
                })
            }
            ,
            publicActiveFormSubmit() {
                this.$refs.publicActiveForm.validate((valid) => {
                    if (valid) {
                        this.fileList.forEach(e => {
                            this.publicActiveForm.images.push(e.url)
                        })
                        this.publicActiveForm.publicUserId = window.sessionStorage.getItem('publicUserId');
                        let formData = JSON.parse(JSON.stringify(this.publicActiveForm))
                        console.log(formData);
                        tokenRequest.post('/api/active/public', formData).then(res => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                })
                                this.getTotal()
                                this.publicActive = false;
                            } else {
                                this.$message({
                                    message: '发生错误，请检查',
                                    type: 'error'
                                })
                            }
                        })
                    }
                })
            }
            ,
            handleRemove: function (file, fileList) {
                console.log(file);
                tokenRequest.post('api/active/images/remove?key=' + file.key).then(res => {
                    console.log(res);


                    this.fileList = fileList

                })
            }
            ,
            handlePreview(file) {
                console.log(file);
            }
            ,
            publicActiveOpen() {
                this.publicActive = true;
                this.fileList = [];
                this.publicActiveForm = {
                    activeTitle: '',
                    images: [],
                    tags: '',
                    activeTime: '2022-05-20 12:00:00',
                    activeAddress: '',
                    activeContent: '',
                    contactMail: '',
                    contactPhone: '',
                    contactAddress: '',
                }
            }
            ,
            handleAvatarSuccess(res, file) {
                console.log(res.key);
                let i = {name: file.name, url: 'https://images.caiqichang.cn/' + res.key, key: res.key}
                this.fileList.push(i)
            }
            ,
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpg';
                const isLt2M = file.size / 1024 / 1024 < 5;

                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG/PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 5MB!');
                }
                return isJPG && isLt2M;
            }
            ,
            cancel() {
                this.dialogVisible = false
            }
            ,
            async toEditUser() {
                this.dialogVisible = false
                const info = await userHttp.updateUserInfo(this.form);
                console.log(info)
                this.getAllUser();
            }
            ,
            GetUserInfoByOpenid(row) {
                this.dialogVisible = true
                this.form.phone = row.phone;
                this.form.remark = row.remark;
                this.form.username = row.username;
                this.form.openid = row.openid
                console.log(row);

            }
            ,
            currentChange(page) {
                this.getAllUser(page)
            }
            ,
            async getAllUser(page) {
                if (!page) {
                    page = 1
                }
                // const allUser = await userHttp.getAllUser(page);
                // this.userList = allUser.data;
                // for (let i = 0; i < this.userList.length; i++) {
                //     if (this.userList[i].sex === 1) {
                //         this.userList[i].sex = "男"
                //     } else {
                //         this.userList[i].sex = "女"
                //     }
                //
                //     //role
                //     if (this.userList[i].role === "1") {
                //         this.userList[i].role = "超级管理员"
                //     } else {
                //         this.userList[i].role = "普通用户"
                //     }
                // }
                // console.log(allUser);
                tokenRequest.post('/api/active/getAll?publicUserId=' + window.sessionStorage.getItem("publicUserId") + '&page=' +page +'&pageSize=' + this.pageSize).then(res => {
                    this.activeList = res.result;
                })
            }
            ,
            getActiveList() {
                tokenRequest.post('/api/active/getAll?publicUserId=' + window.sessionStorage.getItem("publicUserId") + '&page=1&pageSize=' + this.pageSize).then(res => {
                    this.activeList = res.result;
                })
            },
            getTotal() {
                tokenRequest.post('/api/active/getActiveCount?publicUserId=' + window.sessionStorage.getItem("publicUserId")).then(res=>{
                    this.total = res.result
                    this.getActiveList();
                })
            }
        },
        mounted() {
            if (!window.sessionStorage.getItem("reallyName")) {
                this.$message.error("请先完善信息")
                return;
            }
            // this.getAllUser();
            this.getTotal()
            tokenRequest.get('/api/getQnToken').then(res => {
                console.log(res.result.qnToken);
                this.uploadData.token = res.result.qnToken;
            })
        }
    }
</script>

<style scoped>
    .page-el {
        display: flex;
        flex-direction: row-reverse;
    }
</style>
