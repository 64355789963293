<template>
    <div style="background-image: linear-gradient(#e66465, #9198e5);width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;flex-direction: column">
        <div id="main" style="width: 800px;height:400px;"></div>
        <div class="title">管理系统</div>
        <div class="form-data">
            <div class="login-form">
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item prop="phone">
                        <el-input type="text" v-model="ruleForm.phone" autocomplete="off">
                            <template slot="prepend">
                                <i class="el-icon-phone-outline"></i>
                            </template>
                            <template slot="append">
                                <el-button type="primary" @click="sendCode()" v-if="show">点击发送</el-button>
                                <span v-else>再次发送({{count}}s)</span>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="code">
                        <el-input v-model.number="ruleForm.code"></el-input>
                    </el-form-item>
                    <div>
                        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                        <router-link to="/main">index</router-link>
                        <br/>
                        <router-link to="/login2">login2</router-link>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    // import {getUserById} from "../http/userHttp";
    import user from '../http/userHttp'
    export default {
        // name: "login",
        // async created(){
        //   const result = await user.getUserById();
        //     console.log(result);
        // },
        data() {
            var checkCode = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('验证码不能为空'));
                }
                setTimeout(() => {
                    if (!Number.isInteger(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 1000);
            };
            var validatePhone = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入手机号码'));
                }else if(value.length!=11){
                    callback(new Error('请输入11位手机号码'));
                }else {
                    callback();
                }
            };
            return {
                ruleForm: {
                    phone: '13046255574',
                    checkPhone: '',
                    code: ''
                },
                show: true,
                count: '',
                timer: null,
                rules: {
                    phone: [
                        {validator: validatePhone, trigger: 'blur'}
                    ],
                    code: [
                        {validator: checkCode, trigger: 'blur'}
                    ]
                }
            };
        },
        methods: {
             // 倒计时
            getCode() {
                const TIME_COUNT = 10;
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count -= 1;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            },
            // 判断是否是管理员
            isAdmin(){
               return user.isAdmin(this.ruleForm.phone)
            },
            async sendCode() {
                // 是否是管理员 是管理员再发送验证码
                const admin =await this.isAdmin();
                console.log(admin);
                if (admin.data.role === "1") {
                    // 倒计时
                    if (this.ruleForm.phone === '' || this.ruleForm.phone.length !== 11) {
                        this.showMessage("请输入正确的手机号码");
                        return;
                    }else{
                        this.getCode();
                        this.sendPhoneCode();
                        console.log("开始发送验证码~");
                    }

                } else {
                    this.showMessage("不是管理员");
                }

            },
            async sendPhoneCode(){
                const verifyCode=await user.sendSmsCode(this.ruleForm.phone);
                this.$message.success("短信发送成功");
            },
            showMessage(message) {
                this.$message({
                    message: message,
                    type: 'warning'
                });
            },
            // 提交验证
            submitForm(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {
                        // 开始登陆
                        let response = await user.checkCode(this.ruleForm);
                        console.log("----------")
                      console.log(response.code);
                      console.log("----------")
                        if (response.code === 200){
                          console.log("----200了------")
                            window.sessionStorage.setItem('token', response.data.token);
                            window.sessionStorage.setItem('phone', response.data.phone);
                            window.sessionStorage.setItem('reallyName', response.data.reallyName);
                            window.sessionStorage.setItem('mail', response.data.mail);
                            this.$message.success('登录成功！');
                            this.$router.push('/main');
                        }else {
                          console.log("----other-----")
                            this.$message.warning(response.message);
                        }

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            drawChart() {
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.$echarts.init(document.getElementById("main"));
                // 指定图表的配置项和数据
                let option = {
                    xAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        type: 'line'
                    }]
                };

                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(option);
            }

        },
        mounted() {
            this.drawChart();
        }
    }

</script>

<style scoped>
    .form-data {
        border: 2px solid #daebf5;
        border-radius: 20px;
        width: 400px;
        padding: 20px;
    }

    .title {
        margin: 30px;
        font-size: 40px;
    }

    .el-button {
        width: 100%;

    }
</style>
