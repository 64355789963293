<template>
<div>
  <h1 class="title2">首页历史图片</h1>
  <br>
<!--  // action这是上传的url，可以在七牛开发者平台中找到，也可看LZ下面贴出来的图片以及url查找
// qn包括token与key，token是上述生成的上传凭证，自由的key可以上传到七牛中     存储空间对应不同的图片名-->
  <el-upload
      class="upload-demo"
      action="http://upload-z2.qiniup.com"
  :data="qn"
  :show-file-list="false"
  :on-success="handleSuccess"
  :before-upload="beforeUpload"
  :on-error="handleError"
  >
  <img v-if="formData.thumb" :src="formData.thumb" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
  <el-button v-if="formData.thumb" type="primary" @click="uploadImg">点击上传上传<i class="el-icon-upload el-icon--right"></i></el-button>


  <el-divider></el-divider>

  <el-table
      :data="imgList"
      style="width: 100%"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      stripe
      border
  >
    <el-table-column
        prop="id"
        label="序号"
        width="180">
    </el-table-column>

    <el-table-column label="首页图片">
      <template slot-scope="scope">
        <el-popover placement="top-start" title="" trigger="hover">
          <img
              :src="scope.row.imgUrls"
              alt=""
              style="width: 150px;height: 150px"
          />
          <img
              slot="reference"
              :src="scope.row.imgUrls"
              style="width: 150px;height: 150px"
          />
        </el-popover>
      </template>
    </el-table-column>

    <el-table-column
        prop="createTime"
        label="创建时间">
    </el-table-column>
    <el-table-column
        prop="updateTime"
        label="修改时间">
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import picHttp from "../http/picHttp";
export default {
  data(){
    return{
      qn: {
        token: "生成凭证中生成的token",
        key: ""
      },
      formData:{
        thumb:''
      },
      imgList: [],
      fileList: []
    }
  },
  methods: {
    // img字段入库
   async uploadImg(){
      console.log(this.formData.thumb)
      const result = await picHttp.updateIndexImg(this.formData.thumb);
      if (result.code ===2001){
        this.$message.success(result.message);
        this.getIndexImg();
      }else{
        this.$message.error(result.message);
      }
    },
    handleError(err, file, fileList) {    //图片上传失败时调用
      this.$message({
        message: "上传出错，请重试！",
        type: "error",
        center: true
      });
    },
    // 上传成功
    handleSuccess(res, file) {
      this.formData.thumb = "http://qiniu.lcj25.cn/" + res.key;   // 这边显示图片，上传成功后会返回一个对象，包含一个hash和一个key,然后前边加上七牛云为你随机分配的域名就是图片的外链。查看这个域名可看下面的描述
      console.log(this.formData.thumb);
    },
    // 上传之前判断
    beforeUpload(file) {
      this.qn.key = file.name;        // 通过设置key为文件名，上传到七牛中会显示对应的图片名
      // debugger
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt10M) {
        error("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt10M;
    },

    // 获取七牛云的token
    async getQiNiuToken() {
      const qiNiuToken = await picHttp.getQiNiuToken();
      this.qn.token = qiNiuToken.data;
      console.log(qiNiuToken.data)
    },
    // 获取首页的历史图片列表
    async getIndexImg() {
      const imgList1 = await picHttp.getIndexImg();
      this.imgList = imgList1.data;
      console.log(imgList1);
    },
  },
  created() {
    if (!window.sessionStorage.getItem("add")) {
      this.$message.error("暂不可访问")
      return;
    }
    this.getQiNiuToken();
    this.getIndexImg();
  }
}
</script>

<style scoped>
.title2{
  color: transparent;
  background-color : black;
  text-shadow : rgba(255,255,255,0.5) 0 5px 6px, rgba(255,255,255,0.2) 1px 3px 3px;
  -webkit-background-clip : text;
}
.avatar{
  width: 100px;
  height: 100px;
}


</style>