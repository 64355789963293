<template>
<div>
  <h1 class="title2">公告管理</h1><br>
<!--  <el-button type="primary" @click="addNotice">添加公告</el-button>-->
  <el-button type="primary" @click="dialogFormVisible = true">添加公告</el-button>
  <el-dialog title="新增公告" :visible.sync="dialogFormVisible">
    <el-form :model="form">
      <el-form-item label="标题" :label-width="formLabelWidth">
        <el-input v-model="form.title" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="正文" :label-width="formLabelWidth">
        <el-input type="textarea" v-model="form.content"></el-input>
      </el-form-item>

      <el-form-item label="图片" :label-width="formLabelWidth">

        <el-upload
            class="upload-demo"
            action="http://upload-z2.qiniup.com"
            :data="qn"
            :show-file-list="false"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
            :on-error="handleError"
        >
          <img v-if="formData.thumb" :src="formData.thumb" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="addNotice">新 增</el-button>
    </div>
  </el-dialog>
  <br>
  <el-divider></el-divider>

  <el-table
      :data="tableData"
      style="width: 100%"
      :row-class-name="tableRowClassName">
    <el-table-column
        prop="noticeId"
        label="序号"
        width="180">
    </el-table-column>
    <el-table-column
        prop="createAuthor"
        label="姓名"
        width="180">
    </el-table-column>
    <el-table-column
        prop="noticeTitle"
        label="标题">
    </el-table-column>
    <el-table-column
        prop="createTime"
        label="创建时间">
    </el-table-column>
    <el-table-column
        prop="noticeView"
        label="浏览次数">
    </el-table-column>
    <el-table-column label="首页图片">
      <template slot-scope="scope">
        <el-popover placement="top-start" title="" trigger="hover">
          <img
              :src="scope.row.noticeImg"
              alt=""
              style="width: 250px;height: 250px"
          />
          <img
              slot="reference"
              :src="scope.row.noticeImg"
              style="width: 100px;height: 100px"
          />
        </el-popover>
      </template>
    </el-table-column>
  </el-table>
<!--  分页-->
  <div class="page-el">
    <el-pagination
        background
        @current-change="currentChange"
        layout="total,prev, pager, next"
        :total=this.total>
    </el-pagination>
  </div>



</div>
</template>

<script>
import noticeHttp from "../http/noticeHttp";
import picHttp from "../http/picHttp";
import userHttp from "../http/userHttp";
export default {
  data(){
    return{
      qn: {
        token: "生成凭证中生成的token",
        key: ""
      },
      form:{
        title: '',
        content: ''
      },
      formData:{
        thumb:''
      },
      userInfo:{
        openid: '',
        avatar: '',
        username: ''
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      tableData:[],
      total: 0,
      imageUrl: '',
      noticeInfo:{

        },
    }
  },
  methods:{
    //图片上传失败时调用
    handleError(err, file, fileList) {
      this.$message({
        message: "上传出错，请重试！",
        type: "error",
        center: true
      });
    },
    // 上传成功
    handleSuccess(res, file) {
      this.formData.thumb = "http://qiniu.lcj25.cn/" + res.key;   // 这边显示图片，上传成功后会返回一个对象，包含一个hash和一个key,然后前边加上七牛云为你随机分配的域名就是图片的外链。查看这个域名可看下面的描述
      console.log(this.formData.thumb);
    },
    // 上传之前判断
    beforeUpload(file) {
      this.qn.key = file.name;        // 通过设置key为文件名，上传到七牛中会显示对应的图片名
      // debugger
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt10M) {
        error("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt10M;
    },
    //addNotice  入库
     async addNotice(){
      this.dialogFormVisible = false;
       let userInfo = await userHttp.getInfoByPhone(window.sessionStorage.getItem('phone'));
      // var userInfo =this.getInfoByPhone(window.sessionStorage.getItem('phone'));
      var avatar = userInfo.data.avatar;
      var openid = userInfo.data.openid;
      var username = userInfo.data.username;
      // 开始入库
     await this.insertIntoNotice(avatar,openid,username);
    },
    async insertIntoNotice(avatar,openid,username){
      let notice ={
        content:this.form.content,
        title:this.form.title,
        imgUrl:this.formData.thumb,

        openid:openid,
        avatar:avatar,
        username:username
      }

      let result =  await noticeHttp.allNotice(notice);
      if (result.code ===2003){
        // this.$message.success(result.data)
        await this.getAllNotice();
      }
    },
    // 电话获取个人信息
    async getInfoByPhone(phone){
     let userInfo1 = await userHttp.getInfoByPhone(phone);
      return  userInfo1.data;
    },
    currentChange(page){
      this.getAllNotice(page)
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    /**
     * 获取所有公告，分页查询
     */
    async getAllNotice(page){
      let p
      if (page === undefined){
        p =1;
      }else{
        p=page;
      }

      let allNotice =await noticeHttp.getAllNotice(p);
       console.log(allNotice)
      this.tableData = allNotice.data.notices;
       this.total = allNotice.data.total;
    },
    // 获取七牛云的token
    async getQiNiuToken() {
      const qiNiuToken = await picHttp.getQiNiuToken();
      this.qn.token = qiNiuToken.data;
      console.log(qiNiuToken.data)
    },
  },
  mounted() {
    this.getAllNotice();
    this.getQiNiuToken();
  }
}
</script>

<style scoped>
.title2{
  color: transparent;
  background-color : black;
  text-shadow : rgba(255,255,255,0.5) 0 5px 6px, rgba(255,255,255,0.2) 1px 3px 3px;
  -webkit-background-clip : text;
}
.page-el{
  display: flex;
  flex-direction: row-reverse;
}

/**/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>