<template>
    <div class="wrapper">
        <el-container>
            <!--          header-->

            <el-header>
                <template>
                    <!--          <div class="demo-type">-->
                    <!--            <div>-->
                    <!--              <el-avatar icon="el-icon-user-solid"></el-avatar>-->
                    <!--            </div>-->
                    <!--            <div>-->
                    <!--              <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>-->
                    <!--            </div>-->
                    <!--            <div>-->
                    <!--              <el-avatar> user</el-avatar>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <el-dropdown style="float: right;top: 6px" @command="menuHandle">
            <span class="el-dropdown-link">
<!--    下拉菜单<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
              {{ userInfo.reallyName }}
           <el-image style="width: 2vw;height: 2vw;border-radius: 50%"
                   :src="userInfo.avatar"></el-image>
                <!--              <el-avatar>{{this.phoneNumber}}</el-avatar>-->
            </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="updateInfo">修改个人信息</el-dropdown-item>
                            <el-dropdown-item command="updatePassword">修改密码</el-dropdown-item>
                            <el-dropdown-item command="logout">退出登录</el-dropdown-item>

                        </el-dropdown-menu>
                    </el-dropdown>
                </template>


                <!--    updateInfo-->
                <el-dialog title="修改信息" :visible.sync="updateInfo" width="650px">
                    <el-form :model="updateInfoForm" :rules="updateInfoRules" ref="updateInfoForm" autocomplete="off">
                        <el-form-item label="头像" label-width="200px" prop="phoneNumber">
                            <!--              <el-input v-model="updateInfoForm.phoneNumber"-->
                            <!--                    action="https://upload-z2.qiniup.com"               style="width: 300px"></el-input>-->
                            <el-upload
                                    class="avatar-uploader"
                                    action="https://upload-z2.qiniup.com"
                                    :show-file-list="false"
                                    :data="uploadData"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                <img v-if="updateInfoForm.avatar" :src="updateInfoForm.avatar" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="手机号码" label-width="200px" prop="telephone">
                            <el-input v-model="updateInfoForm.telephone" disabled
                                      style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="真实名字" label-width="200px" prop="reallyName">
                            <el-input v-model="updateInfoForm.reallyName"
                                      style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="联系邮箱" label-width="200px" prop="mail">
                            <el-input v-model="updateInfoForm.mail"
                                      style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="组织" label-width="200px" prop="organization">
                            <el-input v-model="updateInfoForm.organization"
                                      style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证" label-width="200px" prop="idCardNumber">
                            <el-input v-model="updateInfoForm.idCardNumber"
                                      style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label-width="200px">
                            <el-button @click="updateInfo=false">取消</el-button>
                            <el-button @click="updateInfoBtn" type="primary">确定</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>

                <!--    updatePassword-->
                <el-dialog title="修改密码" :visible.sync="updatePassword" width="650px">
                    <el-form :model="updatePasswordForm" :rules="updatePasswordRules" ref="updatePasswordForm"
                             autocomplete="off">
                        <el-form-item label="手机号码" label-width="200px" prop="phoneNumber">
                            <el-input v-model="updatePasswordForm.phoneNumber" disabled
                                      style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码" label-width="200px" prop="code">
                            <el-input v-model="updatePasswordForm.code" style="width: 200px"></el-input>
                            <el-button @click="updatePasswordLoadingBtn" style="width: 100px"
                                       :loading="updatePasswordLoading">
                                {{ updatePasswordLoadingText }}
                            </el-button>
                        </el-form-item>
                        <el-form-item label="新密码" label-width="200px" prop="password">
                            <el-input v-model="updatePasswordForm.password" style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码" label-width="200px" prop="secondPassword">
                            <el-input v-model="updatePasswordForm.secondPassword" style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label-width="200px">
                            <el-button @click="updatePassword=false">取消</el-button>
                            <el-button @click="updatePasswordBtn" type="primary">确定</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>


            </el-header>

            <!--container-->
            <el-container>
                <el-aside width="300px">
                    <el-menu
                            :router="true"
                            default-active="2"
                            class="el-menu-vertical-demo"
                            background-color="#334157"
                            text-color="#fff"
                            active-text-color="#DEB350">
                        <el-submenu index="1">
                            <template slot="title">
                                <i class="el-icon-location"></i>
                                <span>首页</span>
                            </template>
                            <el-menu-item-group>
                                <!--                                <template slot="title">首页</template>-->
                                <el-menu-item index="/main/home"><i class="el-icon-setting"></i>首页1</el-menu-item>
<!--                                <el-menu-item index="/main/test1"><i class="el-icon-setting"></i>首页2</el-menu-item>-->
<!--                                <el-menu-item index="/main/test2"><i class="el-icon-setting"></i>首页3</el-menu-item>-->
                            </el-menu-item-group>
                            <!--              <el-menu-item-group>-->

                            <!--                <el-menu-item index="1-3">选项3</el-menu-item>-->
                            <!--              </el-menu-item-group>-->
                            <!--              <el-submenu index="1-4">-->
                            <!--                <template slot="title">选项4</template>-->
                            <!--                <el-menu-item index="1-4-1">选项1</el-menu-item>-->
                            <!--              </el-submenu>-->
                        </el-submenu>
                        <el-menu-item index="/main/activeList">
                            <i class="el-icon-setting"></i>
                            <span slot="title">活动管理</span>
                        </el-menu-item>
                        <el-menu-item index="forum">
                            <i class="el-icon-setting"></i>
                            <span slot="title">活动论坛</span>
                        </el-menu-item>
                        <el-menu-item index="chat">
                            <i class="el-icon-setting"></i>
                            <span slot="title">聊天信息</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>

                <chat-list ref="chat" v-show="false"/>

                <el-main>
                    <router-view/>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import tokenRequest from "../http/tokenRequest";
    import ChatList from "./chatList";

    export default {
        components: {ChatList},
        // name: "main"
        data() {
            return {
                sendMsg: null,
                uploadData: {
                    token: ''
                },
                userInfo: {
                    avatar: null,
                    reallyName: null
                },
                updateInfo: false,
                updateInfoLoadingText: '获取',
                updateInfoLoading: false,
                updateInfoForm: {
                    telephone: '',
                    reallyName: '',
                    avatar: '',
                    organization: '',
                    idCardNumber: '',
                    mail: ''
                },
                updateInfoRules: {
                    telephone: [{required: true, message: '请填写用户名'}],
                    reallyName: [{required: true, message: '请填写真实名字'}],
                    mail: [{required: true, message: '请填写邮箱'}],
                    organization: [{required: true, message: '请填写组织'}],
                    idCardNumber: [{required: true, message: '请填写身份证'}],
                },
                timer: null,
                updatePasswordLoadingText: '获取',
                updatePasswordLoading: false,
                updatePassword: false,
                updatePasswordForm: {
                    phoneNumber: '',
                    code: '',
                    password: '',
                    secondPassword: ''
                },
                updatePasswordRules: {
                    phoneNumber: [{required: true, message: '请填写用户名'}],
                    code: [{required: true, message: '请填验证码'}],
                    password: [{required: true, message: '请填写密码'}],
                    secondPassword: [{required: true, message: '请再次填写密码'}],
                },
                phoneNumber: '',
                websock: null,//建立的连接
                lockReconnect: false,//是否真正建立连接
                timeout: 28 * 1000,//30秒一次心跳
                timeoutObj: null,//心跳心跳倒计时
                serverTimeoutObj: null,//心跳倒计时
                timeoutnum: null,//断开 重连倒计时
            }
        },
        methods: {

            handleAvatarSuccess(res, file) {
                this.updateInfoForm.avatar = URL.createObjectURL(file.raw);
                console.log("uploadImgRes:");
                console.log(res.key);
                console.log(this.updateInfoForm.avatar);
                this.updateInfoForm.avatar = 'https://images.caiqichang.cn/' + res.key;
            },
            beforeAvatarUpload(file) {
                //image/gif,image/png,image/jpg
                const isJPG = file.type === 'image/jpeg'|| file.type==='image/png'|| file.type==='image/gif'|| file.type==='image/jpg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            updatePasswordLoadingBtn() {
                this.updatePasswordLoading = true
                this.timer = setInterval(() => {
                    if (this.updatePasswordLoadingText === 1) {
                        clearInterval(this.timer);
                        this.updatePasswordLoadingText = '获取';
                        this.updatePasswordLoading = false;
                    } else if (this.updatePasswordLoadingText === '获取') {
                        this.updatePasswordLoadingText = 60;
                    } else {
                        this.updatePasswordLoadingText = this.updatePasswordLoadingText - 1;
                    }

                }, 1000)
                tokenRequest.get('/api/phone/getCode?phoneNumber=' + this.updatePasswordForm.phoneNumber).then(res => {
                    if (res.code === 1) {
                        this.$message({
                            message: '获取成功',
                            type: 'success'
                        })
                    }
                })
            },
            updatePasswordBtn() {
                this.$refs.updatePasswordForm.validate((valid) => {
                    if (valid) {
                        let formData = JSON.parse(JSON.stringify(this.updatePasswordForm))
                        tokenRequest.post('/api/phone/password/forget', formData).then(res => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                })
                                this.updatePassword = false;
                            } else {
                                this.$message({
                                    message: '发生错误，请检查',
                                    type: 'error'
                                })
                            }
                        })
                    }
                })
            },
            updateInfoBtn() {
                this.$refs.updateInfoForm.validate((valid) => {
                    if (valid) {
                        let formData = JSON.parse(JSON.stringify(this.updateInfoForm))
                        console.log(formData);
                        tokenRequest.post('/api/phone/updateUserInfo', formData).then(res => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                })
                                this.updateInfo = false;
                                this.updateInfoForm.avatar = res.result.userInfo.avatar
                                this.updateInfoForm.reallyName = res.result.userInfo.reallyName
                                this.updateInfoForm.idCardNumber = res.result.userInfo.idCardNumber
                                this.updateInfoForm.organization = res.result.userInfo.organization
                                this.updateInfoForm.telephone = res.result.userInfo.telephone
                                this.updateInfoForm.mail = res.result.userInfo.mail
                                window.sessionStorage.setItem('reallyName', res.result.userInfo.reallyName);
                            } else {
                                this.$message({
                                    message: '发生错误，请检查',
                                    type: 'error'
                                })
                            }
                        })
                    }
                })
            },
            menuHandle(command) {
                if (command === 'logout') {
                    this.logout()
                } else if (command === 'updateInfo') {
                    tokenRequest.get('/api/phone/userInfo?phoneNumber=' + this.phoneNumber).then(res => {
                        this.updateInfoForm.avatar = res.result.userInfo.avatar
                        this.userInfo.avatar = res.result.userInfo.avatar
                        this.updateInfoForm.reallyName = res.result.userInfo.reallyName
                        this.userInfo.reallyName = res.result.userInfo.reallyName
                        this.updateInfoForm.idCardNumber = res.result.userInfo.idCardNumber
                        this.updateInfoForm.organization = res.result.userInfo.organization
                        this.updateInfoForm.telephone = res.result.userInfo.telephone
                        this.updateInfoForm.mail = res.result.userInfo.mail
                    })
                    console.log('updateInfo');
                    tokenRequest.get('/api/getQnToken').then(res => {
                        console.log(res.result.qnToken);
                        this.uploadData.token = res.result.qnToken;
                    })
                    this.updateInfo = true;
                } else if (command === 'updatePassword') {
                    console.log('updatePassword');
                    this.updatePasswordForm.phoneNumber = this.phoneNumber
                    this.updatePassword = true;
                }
            },
            logout() {
                console.log('logout');
                window.sessionStorage.clear();
                this.$router.push("/")
            },
            initWebSocket() {//建立连接
                //初始化weosocket
                //const wsuri = "ws://sms.填写您的地址.com/websocket/" + this.charId; //ws地址
                // const wsuri = "ws://localhost:8888/system/ws/" + window.sessionStorage.getItem("publicUserId")
                // const wsuri = "ws://8.129.230.25:8888/system/ws/" + window.sessionStorage.getItem("publicUserId")
                if (!window.sessionStorage.getItem("publicUserId")) {
                    this.$message.error("请先登录")
                    this.$router.push("/")
                    return;
                }
                const wsuri = "wss://api.caiqichang.cn/ws/" + window.sessionStorage.getItem("publicUserId")
                //建立连接
                this.websock = new WebSocket(wsuri);
                //连接成功
                this.websock.onopen = this.websocketonopen;
                //连接错误
                this.websock.onerror = this.websocketonerror;
                //接收信息
                this.websock.onmessage = this.websocketonmessage;
                //连接关闭
                this.websock.onclose = this.websocketclose;
            },
            reconnect() {//重新连接
                var that = this;
                if (that.lockReconnect) {
                    return;
                }
                ;
                that.lockReconnect = true;
                //没连接上会一直重连，设置延迟避免请求过多
                that.timeoutnum && clearTimeout(that.timeoutnum);
                that.timeoutnum = setTimeout(function () {
                    //新连接
                    that.initWebSocket();
                    that.lockReconnect = false;
                }, 5000);
            },
            reset() {//重置心跳
                var that = this;
                //清除时间
                clearTimeout(that.timeoutObj);
                clearTimeout(that.serverTimeoutObj);
                //重启心跳
                that.start();
            },
            start() {//开启心跳
                var self = this;
                self.timeoutObj && clearTimeout(self.timeoutObj);
                self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
                self.timeoutObj = setTimeout(function () {
                    //这里发送一个心跳，后端收到后，返回一个心跳消息，
                    if (self.websock.readyState == 1) {//如果连接正常
                        self.websock.send("heartCheck");
                    } else {//否则重连
                        self.reconnect();
                    }
                    self.serverTimeoutObj = setTimeout(function () {
                        //超时关闭
                        self.websock.close();
                    }, self.timeout);

                }, self.timeout)
            },

            websocketonopen() {//连接成功事件

                // //提示成功
                // this.$message.success("连接成功",3)
                //开启心跳
                this.start();
            },

            websocketonerror(e) {//连接失败事件
                //错误
                console.log("WebSocket连接发生错误");
                //错误提示
                // this.$message.error("Websocket error, Check you internet!")
                //重连
                this.reconnect();
            },
            websocketclose(e) {//连接关闭事件
                //关闭
                console.log("connection closed (" + e.code + ")");
                // //提示关闭
                // this.$message.error("连接已关闭",3);
                //重连
                this.reconnect();
            },
            websocketonmessage(event) {//接收服务器推送的信息
                //打印收到服务器的内容
                console.log(event.data);
                let data = JSON.parse(event.data)
                console.log(data);
                // this.$refs.chat.getChatInfoList(data.beChatUserId);
                this.$refs.chat.changeRe(data.beChatUserId);
                // this.sendMsg = data
                // chatList.getChatInfoList()
                this.$notify.info({
                    title: '消息',
                    message: '收到一条消息，请前往聊天信息查看'
                });
                var notification = new Notification('消息提醒', {
                    body: '您有新的消息哦哦',
                    // icon: 'http://assets.souche.com/shop/assets/sso/favicon.ico'// "图标路径，若不指定默认为favicon"
                });


                //收到服务器信息，心跳重置
                this.reset();
            },
            websocketsend(msg) {//向服务器发送信息
                //数据发送
                this.websock.send(msg);
            },
        },
        created() {
            if (!window.sessionStorage.getItem("publicUserId")) {
                this.$message.error("请先登录")
                this.$router.push("/")
                return;
            }
            console.log(window.sessionStorage.getItem('token'));
            this.phoneNumber = window.sessionStorage.getItem('phoneNumber');
            this.updateInfoForm.telephone = window.sessionStorage.getItem('phoneNumber');
            this.userInfo.avatar = window.sessionStorage.getItem('avatar');
            this.userInfo.reallyName = window.sessionStorage.getItem('reallyName');
            this.userInfo.mail = window.sessionStorage.getItem('mail');
            if (this.userInfo.reallyName === "null") {
                this.userInfo.reallyName = this.phoneNumber;
            }
            if (this.userInfo.avatar === "null") {
                this.userInfo.avatar = 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'

            }
            //页面刚进入时开启长连接
            this.initWebSocket();
            Notification.requestPermission();
        }
    }
</script>

<style scoped>

    .wrapper {
        width: 100%;
        height: 100%;
    }

    .el-header {
        background-color: #2b419a;
        color: #333;
        text-align: center;
        line-height: 60px;
    }

    .el-container {
        height: 100%;
    }

    .el-aside {
        background-color: #4d5c6b;
        color: #333;
    }

    .el-main {
        background-color: white;

        color: #333;
    }

    .el-menu {
        border: 0;
    }

    body > .el-container {
        margin-bottom: 40px;
    }

    .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
    }

    .el-icon-arrow-down {
        font-size: 12px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
