<template>
 <div class="bg">
     <div class="login">
         <el-form class="login-container" :model="loginForm" :rules="rules" ref="loginForm" autocomplete="off">
             <h2 class="login_title">活动服务系统登录</h2>
             <el-form-item prop="username">
                 <el-input placeholder="用户名" v-model="loginForm.phoneNumber" prefix-icon="el-icon-user" clearable
                           @keyup.enter.native="submitForm"></el-input>
             </el-form-item>

             <el-form-item prop="password">
                 <el-input placeholder="密码" v-model="loginForm.password" prefix-icon="el-icon-lock" show-password></el-input>
             </el-form-item>
             <el-form-item prop="sidentify">
                 <el-col :span="14">
                     <el-input placeholder="请输入验证码" prefix-icon="el-icon-s-check" maxlength="4" v-model="imageText" @keyup.enter.native="submitForm">
                     </el-input>
                 </el-col>
                 <el-col :span="8">
                     <el-image
                             style="width: 100px;border-radius: 4px;margin-left: 10px"
                             :src="imageCode.url" @click="getImageCode"
                             fit="fit"></el-image>
                 </el-col>
             </el-form-item>
             <el-form-item>
                 <el-button plain @click="forgetPassword=true">忘记密码</el-button>
                 <el-button type="primary" round style="width: 120px" @click="submitForm">登录</el-button>
                 <el-button plain @click="registerUser=true">注册用户</el-button>
             </el-form-item>

         </el-form>

         <!--    forgetPassword-->
         <el-dialog title="重置密码" :visible.sync="forgetPassword" width="650px">
             <el-form :model="forgetPasswordForm" :rules="forgetPasswordRules" ref="forgetPasswordForm"
                      autocomplete="off">
                 <el-form-item label="手机号码" label-width="200px" prop="phoneNumber">
                     <el-input v-model="forgetPasswordForm.phoneNumber" style="width: 300px"></el-input>
                 </el-form-item>
                 <el-form-item label="验证码" label-width="200px" prop="code">
                     <el-input v-model="forgetPasswordForm.code" style="width: 200px"></el-input>
                     <el-button @click="forgetPasswordLoadingBtn" style="width: 100px" :loading="forgetPasswordLoading">
                         {{ forgetPasswordLoadingText }}
                     </el-button>
                 </el-form-item>
                 <el-form-item label="新密码" label-width="200px" prop="password">
                     <el-input v-model="forgetPasswordForm.password" style="width: 300px"></el-input>
                 </el-form-item>
                 <el-form-item label="确认密码" label-width="200px" prop="secondPassword">
                     <el-input v-model="forgetPasswordForm.secondPassword" style="width: 300px"></el-input>
                 </el-form-item>
                 <el-form-item label-width="200px">
                     <el-button @click="forgetPassword=false">取消</el-button>
                     <el-button @click="forgetPasswordBtn" type="primary">确定</el-button>
                 </el-form-item>
             </el-form>
         </el-dialog>

         <!--    registerUser-->
         <el-dialog title="注册用户" :visible.sync="registerUser" width="650px">
             <el-form :model="registerForm" :rules="registerRules" ref="registerForm" autocomplete="off">
                 <el-form-item label="手机号码" label-width="200px" prop="phoneNumber">
                     <el-input v-model="registerForm.phoneNumber" style="width: 300px"></el-input>
                 </el-form-item>
                 <el-form-item label="验证码" label-width="200px" prop="verificationCode">
                     <el-input v-model="registerForm.verificationCode" style="width: 200px"></el-input>
                     <el-button @click="registerUserLoadingBtn" style="width: 100px" :loading="registerUserLoading">
                         {{ registerUserLoadingText }}
                     </el-button>
                 </el-form-item>
                 <el-form-item label="新密码" label-width="200px" prop="password">
                     <el-input v-model="registerForm.password" style="width: 300px"></el-input>
                 </el-form-item>
                 <el-form-item label="确认密码" label-width="200px" prop="secondPassword">
                     <el-input v-model="registerForm.secondPassword" style="width: 300px"></el-input>
                 </el-form-item>
                 <el-form-item label-width="200px">
                     <el-button @click="registerUser=false">取消</el-button>
                     <el-button @click="registerUserBtn" type="primary">确定</el-button>
                 </el-form-item>
             </el-form>
         </el-dialog>
     </div>
 </div>
</template>
<script>
    import tokenRequest from '../http/tokenRequest';

    export default {
        data() {
            return {
                imageText: '',
                imageCode:{},
                registerForm: {
                    phoneNumber: '',
                    verificationCode: '',
                    password: '',
                    secondPassword: ''
                },
                registerRules: {
                    phoneNumber: [{required: true, message: '请填写用户名'}],
                    verificationCode: [{required: true, message: '请填验证码'}],
                    password: [{required: true, message: '请填写密码'}],
                    secondPassword: [{required: true, message: '请再次填写密码'}],
                },
                forgetPasswordForm: {
                    phoneNumber: '',
                    code: '',
                    password: '',
                    secondPassword: ''
                },
                forgetPasswordRules: {
                    phoneNumber: [{required: true, message: '请填写用户名'}],
                    code: [{required: true, message: '请填验证码'}],
                    password: [{required: true, message: '请填写密码'}],
                    secondPassword: [{required: true, message: '请再次填写密码'}],
                },
                rules: {
                    phoneNumber: [{required: true, message: '请填写用户名'}],
                    password: [{required: true, message: '请填写密码'}]
                },
                forgetPassword: false,
                forgetPasswordLoading: false,
                forgetPasswordLoadingText: '获取',
                registerUser: false,
                registerUserLoading: false,
                registerUserLoadingText: '获取',
                timer: null,
                loginForm: {
                    phoneNumber: "",
                    password: ""
                },
            }
        },
        methods: {

            registerUserLoadingBtn() {
                if (/^1[3456789]\d{9}$/.test(this.registerForm.phoneNumber)) {
                    this.registerUserLoading = true
                    this.timer = setInterval(() => {
                        if (this.registerUserLoadingText === 1) {
                            clearInterval(this.timer);
                            this.registerUserLoadingText = '获取';
                            this.registerUserLoading = false;
                        } else if (this.registerUserLoadingText === '获取') {
                            this.registerUserLoadingText = 60;
                        } else {
                            this.registerUserLoadingText = this.registerUserLoadingText - 1;
                        }

                    }, 1000)
                    tokenRequest.get('/api/phone/getCode?phoneNumber=' + this.registerForm.phoneNumber).then(res => {
                        if (res.code === 1) {
                            this.$message({
                                message: '获取成功',
                                type: 'success'
                            })
                        }
                    })
                } else {
                    this.$message({
                        message: '请输入正确手机号码',
                        type: 'warning'
                    })
                }
            },
            forgetPasswordLoadingBtn() {
                //发短信
                console.log('发短信');
                console.log(/^1[3456789]\d{9}$/.test(this.forgetPasswordForm.phoneNumber));
                if (/^1[3456789]\d{9}$/.test(this.forgetPasswordForm.phoneNumber)) {
                    // if (this.forgetPasswordForm.phoneNumber.length===11) {
                    this.forgetPasswordLoading = true
                    console.log(this.forgetPasswordLoading);
                    this.getCodeWait();
                    tokenRequest.get('/api/phone/getCode?phoneNumber=' + this.forgetPasswordForm.phoneNumber).then(res => {
                        if (res.code === 1) {
                            this.$message({
                                message: '获取成功',
                                type: 'success'
                            })
                        }
                    })
                } else {
                    this.$message({
                        message: '请输入正确手机号码',
                        type: 'warning'
                    })
                }

            },
            //验证码等待
            getCodeWait() {
                this.timer = setInterval(() => {
                    if (this.forgetPasswordLoadingText === 1) {
                        clearInterval(this.timer);
                        this.forgetPasswordLoadingText = '获取';
                        this.forgetPasswordLoading = false;
                    } else if (this.forgetPasswordLoadingText === '获取') {
                        this.forgetPasswordLoadingText = 60;
                    } else {
                        this.forgetPasswordLoadingText = this.forgetPasswordLoadingText - 1;
                    }

                }, 1000)
            },
            getImageCode() {

                tokenRequest.post('/api/getImageCode').then(res=>{
                    if (res.code === 1) {
                       this.imageCode = res.result;
                       this.imageCode.url = "data:image/png;base64," + this.imageCode.url;
                    }
                })
            },
            //忘记密码
            forgetPasswordBtn() {
                console.log('忘记密码');
                this.$refs.forgetPasswordForm.validate((valid) => {
                    if (valid) {
                        let formData = JSON.parse(JSON.stringify(this.forgetPasswordForm))
                        tokenRequest.post('/api/phone/password/forget', formData).then(res => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                })
                                this.forgetPassword = false;
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    }
                })
            },
            // 注册
            registerUserBtn() {
                console.log('注册用户');
                this.$refs.registerForm.validate((valid) => {
                    if (valid) {
                        let formData = JSON.parse(JSON.stringify(this.registerForm))
                        tokenRequest.post('/api/phone/password/register', formData).then(res => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '注册成功',
                                    type: 'success'
                                })
                                this.registerUser = false;
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    }
                })
            },
            // 验证
            submitForm() {
                this.$refs.loginForm.validate((valid) => {
                    if (valid) {
                        if(this.imageCode.text!==this.imageText) {
                            this.$message.error('请输入正确的验证码')
                            this.getImageCode();
                            return;
                        }
                        let formData = JSON.parse(JSON.stringify(this.loginForm))
                        console.log(formData);
                        // 登录
                        tokenRequest.post('/api/phone/password/login', formData).then(res => {
                            console.log(res.code);
                            if (res.code === 1) {
                                //写入token
                                window.sessionStorage.setItem('token', res.result.token)
                                window.sessionStorage.setItem('phoneNumber', res.result.userInfo.telephone)
                                window.sessionStorage.setItem('reallyName', res.result.userInfo.reallyName)
                                window.sessionStorage.setItem('avatar', res.result.userInfo.avatar)
                                window.sessionStorage.setItem('avatar', res.result.userInfo.avatar)
                                window.sessionStorage.setItem('publicUserId', res.result.userInfo.id)
                                console.log(res.result.token);
                                this.$message.success({
                                    message: '登录成功',
                                    type: 'success'
                                });
                                console.log(window.sessionStorage.getItem('token'));
                                this.$router.push("/main")
                            } else {
                                this.$message.success({
                                    message: '登录失败，请检查账号密码！',
                                    type: 'error'
                                });
                            }
                        })


                    }
                });
            },

        },
        created() {
            this.getImageCode();
        }
    }
</script>

<style scoped>
    /*.login{*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*    background-color: #2d3a4b;*/
    /*}*/
    .login-container {
        border-radius: 15px;
        background-clip: padding-box;
        /*margin: 170px auto;*/
        margin-top: 50%;
        width: 350px;
        padding: 35px 35px 15px 35px;
        background: #fff;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 25px #cac6c6;
    }

    .login_title {
        margin: 0px auto 40px auto;
        text-align: center;
        color: #505458;
    }

    .bg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        /*background: center top no-repeat url("https://www.caiqichang.cn/images/bg-admin.jpg");*/
        background: center top no-repeat url("https://images5.alphacoders.com/121/thumb-1920-1217672.jpg");
        background-size: cover;
    }
</style>
