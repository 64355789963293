import request from "./request";

/**
 * 关于用户 的请求
 */
export default {
    getUserById() {
        return request.get(`/lcj/lcj1`)
    },
    sendSmsCode(phone){
        return request.post(`/sms/send/${phone}`)
    },
    isAdmin(phone){
        return request.get(`/wx/isAdmin?phone=${phone}`)
    },
    checkCode ({code,phone}){
        return request.post(`/sms/checkCode?code=${code}&phone=${phone}`)
    },
    getInfoByPhone(phone){
        return request.get(`/wx/getInfoByPhone?phone=${phone}`)
    },
    // 获取用户列表
    getAllUser(page){
        return request({
            method: 'get',
            url: '/wx/findAllUser?page='+page,
        })
    },
    // 修改用户信息
    updateUserInfo({username,remark,phone,openid}){
        return request({
            method: 'POST',
            url: '/wx/updateAllUser',
            data:{
                username:username,
                remark:remark,
                phone:phone,
                openid:openid
            }
        })
    }
}

