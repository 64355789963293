<template>
    <div id="login">
        <div class="loginConbox">
            <div class="header">
                <!--<div class="logo">-->
                <!--<img src="../../assets/logo.png">-->
                <!--</div>-->
            </div>
            <div class="loginBox">
                <div class="loginCon">
                    <p class="title">nodePlatform</p>
                    <p class="title">前台: vue + element-ui</p>
                    <p class="title">后台: egg.js</p>
                    <el-card shadow="always" class="login-module" v-if="smdl">
                        <div slot="header" class="clearfix formTitlt">
                            <span>密码登录</span>
                            <span class="titIconbox">
              <i class="iconfont xu-saomadenglu2 fa-lg iconcolor"></i>
              <i class="iconfont xu-saomadenglu01 el-icon--right fa-lg pointer" @click="smdl = !smdl"></i>
            </span>
                        </div>
                        <el-form :model="loginForm" status-icon label-width="100px" class="demo-ruleForm">
                            <el-form-item>
                                <el-input type="text" v-model="loginForm.username" auto-complete="off" placeholder="请输入登录账号"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input type="password" v-model="loginForm.password" auto-complete="off"
                                          placeholder="请输入登录密码"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button class="subBtn" type="primary" @click="submitForm">登录</el-button>
                            </el-form-item>
                            <p class="smalltxt">
                                <router-link class="a" to="#">忘记密码</router-link>
                                <router-link class="a" to="#">忘记会员名</router-link>
                                <router-link class="a" to="#">免费注册</router-link>
                            </p>
                        </el-form>
                    </el-card>

                    <el-card shadow="always" class="login-module" v-else>
                        <div slot="header" class="clearfix formTitlt">
                            <span>扫码登录</span>
                            <span class="titIconbox">
              <i class="iconfont xu-mimadenglu1 fa-lg iconcolor"></i>
              <i class="iconfont xu-imagevector el-icon--right fa-lg pointer" @click="smdl = !smdl"></i>
            </span>
                        </div>
                        <div class="ewmbox">
                            <div class="ewm">
                                <img src="https://img.alicdn.com/tfscom/TB1ivYYyHvpK1RjSZFqwu3XUVXa.png">
                            </div>
                            <div class="ewmicon">
                                <i class="iconfont xu-saomadenglu fa-2x iconcolor"></i>
                                <p>打开 微信 扫一扫登录</p>
                            </div>
                            <p class="smalltxt">
                                <router-link class="a" to="#">免费注册</router-link>
                            </p>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                smdl: true,
                loginForm: {
                    username: "admin",
                    password: "admin"
                }
            }
        },
        methods: {
            submitForm () {

            }
        },
        mounted () {

        }
    }
</script>
<style scoped>
    #login {
        width: 100%;
        height: 100%;
        background-color: #2d3a4b;
    }
    #login .loginConbox {
        background: #2d3a4b;
    }
    #login .header {
        height: 60px;
        position: relative;
        background: #2d3a4b;
        /*border-bottom: 1px solid rgba(255, 255, 255, 0.3);*/
    }
    #login .header .logo {
        margin-left: 30px;
        width: 500px;
        float: left;
        height: 40px;
        padding-top: 10px;
    }
    #login .header .logo img {
        height: 100%;
    }
    #login .loginBox {
        padding: 74px 0 118px;
    }
    #login .loginBox .iconcolor {
        color: #409EFF;
    }
    #login .loginBox .loginCon {
        width: 990px;
        margin: auto;
        position: relative;
        height: 388px;
    }
    #login .loginBox .loginCon .el-card__header {
        border-bottom: 0px;
    }
    #login .loginBox .loginCon .title {
        font-size: 36px;
        font-weight: 600;
        color: #ffffff;
        width: 500px;
        float: left;
        margin-top: 0px;
    }
    #login .loginBox .loginCon .title:first-child {
        font-size: 50px;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    #login .loginBox .loginCon .login-module {
        width: 380px;
        height: 325px;
        margin-top: 60px;
        border: none;
        position: absolute;
        right: 0;
    }
    #login .loginBox .loginCon .login-module .formTitlt {
        font-size: 18px;
        font-weight: 400;
    }
    #login .loginBox .loginCon .login-module .formTitlt .titIconbox {
        float: right;
    }
    #login .loginBox .loginCon .login-module .formTitlt .titIconbox .pointer {
        cursor: pointer;
    }
    #login .loginBox .loginCon .login-module .smalltxt {
        text-align: right;
    }
    #login .loginBox .loginCon .login-module .smalltxt .a {
        text-decoration: none;
        color: #999999;
        font-size: 12px;
        margin-left: 8px;
    }
    #login .loginBox .loginCon .el-form-item__content {
        margin-left: 0px !important;
    }
    #login .loginBox .loginCon .el-form-item__content .subBtn {
        width: 100%;
    }
    #login .loginBox .el-input__inner, #login .loginBox .el-button, #login .loginBox .el-card, #login .loginBox .el-message {
        border-radius: 0px !important;
    }
    #login .loginBox .el-form-item__content .ico {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 999;
        width: 40px;
        height: 39px;
        text-align: center;
        border-right: 1px solid #ccc;
    }
    #login .loginBox .ewmbox {
        width: 100%;
        height: 240px;
        margin-top: -25px;
    }
    #login .loginBox .ewmbox .ewm {
        width: 140px;
        height: 140px;
        margin: 20px auto;
    }
    #login .loginBox .ewmbox .ewm p {
        font-size: 12px;
        padding-left: 40px;
        margin: 0;
    }
    #login .loginBox .ewmbox .ewmicon {
        width: 140px;
        margin: 20px auto 0;
    }
    #login .loginBox .ewmbox .ewmicon .iconfont {
        float: left;
    }
    #login .loginBox .ewmbox .ewmicon p {
        font-size: 12px;
        padding-left: 40px;
        margin: 0;
    }

    #login {
        width: 100%;
        height: 100%;
        background-color: #2d3a4b;
    }
    #login .loginConbox {
        background: #2d3a4b;
    }
    #login .header {
        height: 60px;
        position: relative;
        background: #2d3a4b;
        /*border-bottom: 1px solid rgba(255, 255, 255, 0.3);*/
    }
    #login .header .logo {
        margin-left: 30px;
        width: 500px;
        float: left;
        height: 40px;
        padding-top: 10px;
    }
    #login .header .logo img {
        height: 100%;
    }
</style>
