<template>
    <div>
        <el-table
                :data="tableData"
                style="width: 100%">
            <el-table-column
                    label="头像"
                    width="100">
                <template slot-scope="scope">
                    <el-avatar :shape="'square'" size="large"
                               :src="scope.row.avatar"></el-avatar>
                </template>
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="姓名"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="creatTime"
                    label="联系时间"
                    width="180">
            </el-table-column>
            <el-table-column
                    label="操作"
                    width="180">
                <template slot-scope="scope">
                    <el-button type="primary" @click="showChat(scope.row)">聊天</el-button>
                    <!--        <el-button type="danger" @click="">删除</el-button>-->
                    <!--                <el-button type="primary" @click="GetUserInfoByOpenid(scope.row)">修改</el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <el-dialog

                width="1100px"
                height="980px"
                :title="'聊天-'+chatName"
                :visible.sync="dialogVisible"
                :before-close="handleClose">
            <!--         <div>-->
            <!--             <el-image-->
            <!--                     style="width: 50px; height: 50px;border-radius: 50%;"-->
            <!--                     src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"-->
            <!--                     fit="cover"></el-image>-->
            <!--             <p>：你好</p>-->
            <!--             <el-image-->
            <!--                     style="width: 50px; height: 50px;border-radius: 50%;"-->
            <!--                     src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"-->
            <!--                     fit="cover"></el-image>-->
            <!--             <p>:你好</p>-->
            <!--         </div>-->
            <!--      <div>-->
            <!--        <div v-for="item in chatListInfo">-->
            <!--          <div :class="item.site" v-if="item.site==='left'">-->

            <!--            <span>{{ item.creatTime }}</span>-->
            <!--            <div style="display: flex;">-->
            <!--              <el-image style="width: 50px; height: 50px;border-radius: 50%;margin-top: 1%"-->
            <!--                        :src="item.avatar">-->

            <!--              </el-image>-->
            <!--&lt;!&ndash;              <div style="margin:3% 0 0 2%">  <span>{{ item.name }}</span>&ndash;&gt;-->
            <!--&lt;!&ndash;                <span>{{ '：' }}</span></div>&ndash;&gt;-->
            <!--              <div style="margin:2% 1% 1% 1%;background-color: antiquewhite;border-radius: 30px;width: 300px;height: 50px"-->
            <!--                   :style="{'height' : item.content.length>=19 ? Math.ceil( item.content.length/19) * 28 + 'px' : '40px'}">-->
            <!--                <div style="margin: 4%;">-->
            <!--                  <span>{{ item.content }}</span>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div :class="item.site" v-else>-->
            <!--            <span style="text-align: center">{{ item.creatTime }}</span>-->
            <!--            <div style="display: flex;justify-content: flex-end;">-->

            <!--              <div style="margin:2% 1% 1% 1%;background-color: antiquewhite;border-radius: 30px;width: 300px;height: 40px;"-->

            <!--                   :style="{'height' : item.content.length>=19 ? Math.ceil( item.content.length/19) * 28 + 'px' : '40px'}">-->
            <!--                <div style="margin: 4%;">-->
            <!--                  <span>{{ item.content }}</span>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--&lt;!&ndash;              <div style="margin: 3% 2% 0 0">&ndash;&gt;-->
            <!--&lt;!&ndash;                <span>{{ '：' }}</span>&ndash;&gt;-->
            <!--&lt;!&ndash;                <span>{{ item.name }}</span>&ndash;&gt;-->
            <!--&lt;!&ndash;              </div>&ndash;&gt;-->
            <!--              <el-image style="width: 50px; height: 50px;border-radius: 50%;margin-top: 1%"-->
            <!--                        :src="item.avatar">-->
            <!--              </el-image>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--        <div style="margin: 4%;"></div>-->
            <!--        <div style="display: flex;justify-content: center;">-->
            <!--          <el-input v-model="msg.content" style="width: 300px"/>-->
            <!--          <el-button type="primary" @click="senMsg">发送</el-button>-->
            <!--        </div>-->
            <!--      </div>-->

            <!--      <el-scrollbar style="height: 325px;width: 300px;">-->
            <el-scrollbar ref="myScrollbar" style="height: 600px;background-color: #f1f1f1;">
                <div>
                    <div v-for="item in chatListInfo">
<!--                        <div :class="item.site" v-if="item.site==='left'">-->

<!--                            <span>{{ item.creatTime }}</span>-->
<!--                            <div style="display: flex;">-->
<!--                                &lt;!&ndash;                <el-image style="width: 50px; height: 50px;border-radius: 50%;margin-top: 1%"&ndash;&gt;-->
<!--                                &lt;!&ndash;                          :src="item.avatar">&ndash;&gt;-->

<!--                                <el-avatar shape="square" :src="item.avatar"></el-avatar>-->
<!--                                &lt;!&ndash;                </el-image>&ndash;&gt;-->
<!--                                &lt;!&ndash;              <div style="margin:3% 0 0 2%">  <span>{{ item.name }}</span>&ndash;&gt;-->
<!--                                &lt;!&ndash;                <span>{{ '：' }}</span></div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                <div&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        style="margin:0 1% 1% 1%;background-color: antiquewhite;border-radius: 30px;width: 300px;height: 50px"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        :style="{'height' : item.content.length>=19 ? Math.ceil( item.content.length/19) * 28 + 'px' : '40px'}">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <div style="margin: 4%;">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        <span>{{ item.content }}</span>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                <div&ndash;&gt;-->
<!--&lt;!&ndash;                                        style="margin:0 1% 1% 1%;width: 300px">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div style="margin: 4%;background-color: antiquewhite;border-radius: 15px;padding: 5%">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <span style="">{{ item.content }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--                                <div class="leftMsg">{{item.content}}</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div :class="item.site" v-else>-->
<!--                            <span style="text-align: center">{{ item.creatTime }}</span>-->
<!--                            <div style="display: flex;justify-content: flex-end;">-->

<!--&lt;!&ndash;                                <div&ndash;&gt;-->
<!--&lt;!&ndash;                                        style="margin:0 1% 1% 1%;background-color: antiquewhite;border-radius: 30px;width: 300px;height: 40px;"&ndash;&gt;-->

<!--&lt;!&ndash;                                        :style="{'height' : item.content.length>=19 ? Math.ceil( item.content.length/19) * 28 + 'px' : '40px'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div style="margin: 4%;">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <span>{{ item.content }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                <div&ndash;&gt;-->
<!--&lt;!&ndash;                                        style="margin:0 1% 1% 1%;width: 300px;">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div style="margin: 4%;padding: 5%;background-color: antiquewhite;border-radius: 15px;">&ndash;&gt;-->
<!--&lt;!&ndash;                                        {{ item.content }}&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--                                <div class="rightMsg">{{item.content}}</div>-->
<!--                                &lt;!&ndash;              <div style="margin: 3% 2% 0 0">&ndash;&gt;-->
<!--                                &lt;!&ndash;                <span>{{ '：' }}</span>&ndash;&gt;-->
<!--                                &lt;!&ndash;                <span>{{ item.name }}</span>&ndash;&gt;-->
<!--                                &lt;!&ndash;              </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                <el-image style="width: 50px; height: 50px;border-radius: 50%;margin-top: 1%"&ndash;&gt;-->
<!--                                &lt;!&ndash;                          :src="item.avatar">&ndash;&gt;-->
<!--                                &lt;!&ndash;                </el-image>&ndash;&gt;-->
<!--                                <el-avatar shape="square" :src="item.avatar"></el-avatar>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        start-->
                        <!-- 单个消息1 客服发出（左） -->
                        <div v-if="item.site==='left'" style='display: flex; padding: 1vw 5vw 1vw 1vw;'>
                            <div style='width: 2.5vw; height: 2.5vw;'>

                                <el-image style='width: 2.5vw; height: 2.5vw; border-radius: 6px;' :src="item.avatar"></el-image>
                            </div>
                            <div style='width: 1.5vw; height: 1.5vw;margin-top: 10px;margin-right: -10px;display: flex; align-items: center; z-index: 9;'>
                                <el-image style='width: 1.5vw;' :src="require(`./../assets/right.png`)" mode='widthFix'></el-image>
                            </div>
                            <div class='leftMsg'>{{item.content}}</div>
<!--                            <span style="text-align: center">{{ item.creatTime }}</span>-->
                        </div>


                        <!-- 单个消息2 用户发出（右） -->
                        <div v-else  style='display: flex; justify-content: flex-end; padding: 1vw 1vw 1vw 15vw;'>
                            <div class='rightMsg'>{{item.content}}</div>
                            <div style='width: 1.5vw; height: 1.5vw; margin-left: -10px;margin-top: 14px;margin-right: 0.5vw; display: flex; align-items: center; z-index: 9;'>
                                <el-image style='width: 1.5vw;' :src="require(`./../assets/left.png`)" mode='widthFix'></el-image>
                            </div>
                            <div style='width: 2.5vw; height: 2.5vw;'>
                                <el-image style='width: 2.5vw; height: 2.5vw; border-radius: 6px;' :src="item.avatar"></el-image>
                            </div>
                        </div>
                        <span style="display:block;text-align: center">{{ item.creatTime }}</span>
                    </div>
                </div>
            </el-scrollbar>
            <div style="margin: 4%;"></div>
            <div style="display: flex;justify-content: center;">
                <el-input clearable type="text" @keyup.enter.native="senMsg" autofocus v-model="msg.content" style="width: 300px"/>
                <el-button type="primary" @click="senMsg">发送</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="closeChat">取 消</el-button>
    <el-button type="primary" @click="closeChat">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import tokenRequest from "../http/tokenRequest";

    export default {
        name: "chatList",
        // props: ["sendMsg"],
        data() {
            return {
                chatName: '',
                beChatUserId: '',
                msg: {
                    chatUserId: '',
                    beChatUserId: '',
                    content: '',
                    type: '0',
                    site: 'right',
                    name: 'bom',
                    chatTime: '2022-02-24 12:00:00',
                    avatar: 'http://images.caiqichang.cn/FjRIHzEsWGd0WaNd_fLe5F0S5Rwx'
                },
                tableData: [],
                dialogVisible: false,
                chatListInfo: [],
                refreshMsg: false,
                timer: null,
            }
        },
        updated() {
            this.scrollDown()

        },
        watch: {},
        methods: {
            scrollDown() {
                if (this.$refs['myScrollbar'])
                    this.$refs['myScrollbar'].wrap.scrollTop = this.$refs['myScrollbar'].wrap.scrollHeight
            },
            senMsg() {
                console.log('sendMsg');
                if (this.msg.content !== '') {
                    this.msg.chatTime = new Date().toLocaleDateString().replace('/', '-') + ' ' + new Date().toLocaleTimeString()
                    // this.chatListInfo.push(this.msg)
                    // console.log(this.msg);
                    tokenRequest.post('/chat', this.msg).then(res => {
                        console.log(res);
                        // this.getChatInfoList()
                        this.chatListInfo.push(res.result)
                        this.msg.content = ''

                    }).catch(res => {
                        this.$message.error('发送失败')
                    })
                    // this.msg = {
                    //   chatUserId: this.msg.chatUserId,
                    //   beChatUserId: this.msg.beChatUserId,
                    //   content: '',
                    //   type: '0',
                    //   site: 'right',
                    //   name: 'bom',
                    //   creatTime: '2022-02-24 12:00:00',
                    //   avatar: 'http://images.caiqichang.cn/FjRIHzEsWGd0WaNd_fLe5F0S5Rwx'
                    // }
                } else {
                    this.$message.info('消息不能为空')
                }
            },
            closeChat() {
                clearInterval(this.timer)
                this.dialogVisible = false;
                this.chatListInfo = []

            },
            showChat(row) {
                this.chatName = row.name
                this.msg.chatUserId = window.sessionStorage.getItem("publicUserId")
                this.msg.beChatUserId = row.id
                this.getChatInfoList(row.id)
                this.dialogVisible = true

                this.timer = setInterval(() => {
                    console.log(this.dialogVisible);

                    if (this.dialogVisible) {
                        if (this.msg.beChatUserId.length > 10) {
                            this.getChatInfoList(this.msg.beChatUserId)
                        }
                    }
                }, 900)
            },
            getChatList() {
                tokenRequest.post('/getAllByPublic?id=' + window.sessionStorage.getItem("publicUserId"))
                    .then(res => {
                        this.tableData = res.result
                    })
            },
            getChatInfoList(beChatUserId) {
                if (this.msg.beChatUserId.length <= 10) {
                    this.msg.beChatUserId = beChatUserId
                }
                if (this.msg.beChatUserId)
                    tokenRequest.post('/getChatList?id=' + window.sessionStorage.getItem("publicUserId")
                        + "&beChatUserId=" + this.msg.beChatUserId
                    )
                        .then(res => {
                                this.chatListInfo = res.result

                            // this.$refs['myScrollbar'].wrap.scrollTop = this.$refs['myScrollbar'].wrap.scrollHeight
                        })
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                        this.closeChat()
                    })
                    .catch(_ => {
                    });
            },
            changeRe(bid) {
                this.refreshMsg = true;
                this.msg.beChatUserId = bid;
            }
        },
        created() {
            if (!window.sessionStorage.getItem("reallyName")) {
                this.$message.error("请先完善信息")
                return;
            }
            this.getChatList()
        },
        mounted() {
            // this.getChatInfoList()
        }
    }
</script>

<style scoped>
    .left {
        text-align: start;
        margin: 10px;
    }

    .right {
        text-align: end;
        margin: 10px;
    }
    .leftMsg {
        font-size: 22px;
        color: #444;
        line-height: 1.7vw;
        padding: 0.7vw 1.1vw;
        background-color: #fff;
        /*margin-left: 0.8vw;*/
        border-radius: 10px;
        z-index: 10;
    }

    .rightMsg {
        font-size: 22px;
        color: #444;
        line-height: 1.7vw;
        padding: 0.7vw 1.1vw;
        background-color: #96EB6A;
        /*margin-right: 0.8vw;*/
        border-radius: 10px;
        z-index: 10;
    }
</style>