<template>
    <div>
        <h1 class="title2">活动论坛</h1>
        <el-divider></el-divider>
        <div v-for="item in dynamic" style="width: 800px;">
            <div style="display: flex;">
                <el-avatar :shape="'square'" size="large"
                           :src="item.dynamic.publicAvatar"></el-avatar>
                <p style="padding-left: 1%;font-size: 28px;font-weight: 500">{{item.dynamic.publicName}}</p>
            </div>
            <div style="font-size: 26px;font-weight: 400;padding: 2%">
                <p>
                    {{item.dynamic.content}}
                </p>
            </div>
            <div style="padding: 1%">
                <el-image v-for="image in item.images" :key="image.id"
                          style="width: 40%;height: 40%"
                          :src="image.url"></el-image>
            </div>
            <div style="padding: 1%">
                {{item.dynamic.createTime}}
            </div>
            <div style="display: flex;margin: 2%">
                <el-input :placeholder="commentText" clearable type="text"
                          @keyup.enter.native="senComment(item.dynamic)" autofocus
                          v-model="comment.commentContent" style="width: 300px"/>
                <el-button type="primary" @click="senComment(item.dynamic)">评论</el-button>
            </div>
            <div style="margin: 1%;background-color: #F7F7F7">
                <div style="padding: 3px;display: flex" v-for="comment in item.comments">
                    <!--                    <el-avatar :shape="'circle' " size="small"-->
                    <!--                               :src="comment.commenterAvatar"></el-avatar>-->
                    <!--                    <p style="padding-left: 3px;font-size: 23px;font-weight: 500">lcj:</p>-->
                    <!--                    <p style="padding-left: 3px;font-size: 23px;font-weight: 500">你好</p>-->
                    <div v-if="comment.parentCommenterId==null" style="display: flex;padding: 2px">
                        <el-avatar :shape="'circle' " size="small"
                                   :src="comment.commenterAvatar"></el-avatar>
                        <p style="padding-left: 3px;font-size: 23px;font-weight: 500">{{comment.commenterNickname}}:</p>
                        <p style="padding-left: 3px;font-size: 23px;font-weight: 500">{{comment.commentContent}}</p>
                    </div>
                    <div v-else style="display: flex;padding: 2px">
                        <el-avatar :shape="'circle' " size="small"
                                   :src="comment.commenterAvatar"></el-avatar>
                        <p style="padding-left: 3px;font-size: 23px;font-weight: 500">
                            {{comment.commenterNickname}}@{{comment.parentCommenterNickname}}:</p>
                        <p style="padding-left: 3px;font-size: 23px;font-weight: 500">{{comment.commentContent}}</p>
                    </div>
                    <p style="margin-left: 20%;font-size: 20px;color: blue">
                        <i class="el-icon-s-comment" @click="secondComment(comment)" style="margin-left: 3px"></i>
                        <i class="el-icon-delete" style="margin-left: 3px"
                           v-if="comment.commenterId === publicUserId" @click="delComment(comment.id)"></i>
                    </p>

                </div>
                <!--                <div style="padding-bottom: 3px;display: flex">-->
                <!--                    <el-avatar :shape="'circle' " size="small"-->
                <!--                               src="http://images.caiqichang.cn/FjRIHzEsWGd0WaNd_fLe5F0S5Rwx"></el-avatar>-->
                <!--                    <p style="padding-left: 3px;font-size: 23px;font-weight: 500">cqc@lcj:</p>-->
                <!--                    <p style="padding-left: 3px;font-size: 23px;font-weight: 500">你好</p>-->
                <!--                </div>-->
            </div>
            <div>
                <el-divider></el-divider>
            </div>
        </div>

    </div>

</template>

<script>
    import tokenRequest from "../http/tokenRequest";

    export default {
        data() {
            return {
                dynamic: [],
                commentText: '快来输入你的神评吧!',
                publicUserId: '',
                comment: {
                    commentContent: ''
                },
                commentType: 0,
                parentCommenter: {},
            }
        },
        methods: {
            senComment(e) {
                if (this.comment.commentContent.length > 0) {

                    if (this.commentType === 0) {
                        let comment = {
                            commentContent: this.comment.commentContent,
                            commenterAvatar: window.sessionStorage.getItem('avatar'),
                            commenterNickname:window.sessionStorage.getItem('reallyName'),
                            commenterId:window.sessionStorage.getItem('publicUserId'),
                            dynamicId : e.id
                        }
                        this.comment = comment;
                    } else {
                        let comment = {
                            commentContent: this.comment.commentContent,
                            commenterAvatar: window.sessionStorage.getItem('avatar'),
                            commenterNickname:window.sessionStorage.getItem('reallyName'),
                            commenterId:window.sessionStorage.getItem('publicUserId'),
                            dynamicId : e.id,
                            parentCommenterAvatar:this.parentCommenter.parentCommenterAvatar,
                            parentCommenterId: this.parentCommenter.parentCommenterId,
                            parentCommenterNickname: this.parentCommenter.parentCommenterNickname
                        }
                        this.comment = comment;
                    }
                    console.log('this.comment:');
                    console.log(this.comment);
                    tokenRequest.post('/api/dynamic/comment',this.comment).then(res=> {
                        if (res.code===1) {
                            this.getAllDynamic()
                           this.comment={
                                commentContent: ''
                            };
                            this.commentText = '快来输入你的神评吧!';
                        }
                    })
                } else {
                    this.$message.error('评论内容不能为空')
                }
            },
            secondComment(e) {

                if (this.commentType === 0) {

                    this.comment.commentContent = ''
                    this.commentType = 1;
                    this.parentCommenter.parentCommenterAvatar = e.commenterAvatar;
                    this.parentCommenter.parentCommenterId = e.id;
                    this.parentCommenter.parentCommenterNickname = e.commenterNickname;
                    this.commentText = '回复：@' + e.commenterNickname;
                } else {
                    this.comment.commentContent = ''
                    this.commentType = 0;
                    this.parentCommenter.parentCommenterAvatar = '';
                    this.parentCommenter.parentCommenterId = '';
                    this.parentCommenter.parentCommenterNickname = '';
                    this.commentText = '快来输入你的神评吧!';
                }
            },

            delComment(e) {
                console.log(e);
                this.$confirm('删除这条评论？','提示',{
                    type: 'warning'
                }).then(res=>{
                    console.log(res ==='confirm');
                    if (res ==='confirm') {
                        //TODO 删除评论
                        tokenRequest.post('/api/delete/comment?commentId=' + e).then(res=>{
                            if (res.code ===1) {
                                this.getAllDynamic();
                                this.$message.info('删除成功')
                            } else {
                                this.$message.error('操作失败')
                            }
                        })
                    }
                }).catch(res=>{
                    this.$message('已取消')
                })
            },

            getAllDynamic() {

                tokenRequest.post('/api/dynamic/getAll').then(res => {
                    console.log(res.result);
                    this.dynamic = res.result
                })
            }
        },
        created() {
            if (!window.sessionStorage.getItem("publicUserId") ||
                !window.sessionStorage.getItem("reallyName") ||
                !window.sessionStorage.getItem("phoneNumber") ||
                !window.sessionStorage.getItem("avatar") ||
                !window.sessionStorage.getItem("token")) {
                this.$message.error("请先完善个人信息")
                this.publicUserId = window.sessionStorage.getItem("publicUserId");
                return;
            }
            this.publicUserId = window.sessionStorage.getItem("publicUserId");
            this.getAllDynamic()

        }
    }
</script>

<style scoped>
    .title2 {
        color: transparent;
        background-color: black;
        text-shadow: rgba(255, 255, 255, 0.5) 0 5px 6px, rgba(255, 255, 255, 0.2) 1px 3px 3px;
        -webkit-background-clip: text;
    }

    .avatar {
        width: 100px;
        height: 100px;
    }


</style>