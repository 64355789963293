import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../components/login'
import IndexPic from '../components/IndexPic'
import notice from '../components/notice'
import forum from '../components/forum'

Vue.use(VueRouter)
import error from '../components/error'
import mainPage from '../components/main'
import mainTest1 from "../components/mainTest1";
import mainTest2 from "../components/mainTest2";
import home from '../components/home';
import userList from '../components/userList'
import login2 from '../components/login2'
import chatList from "../components/chatList";
import activeList from "../components/activeList";

Vue.use(VueRouter);

const routes = [
    // {
    //     path: '/',
    //     component: login
    // },
    // {
    //     path: '/login2',
    //     component: login2
    // },
    {
        path: '/',
        component: login2
    },
    {
        path: '/login',
        component: login2
    },
    {
        path: '/main',
        component: mainPage,
        redirect: '/main/home',
        children: [
            {
                path: '/main/home',
                component: home
            },
            {
                path: '/main/test1',
                component: mainTest1
            },
            {
                path: '/main/test2',
                component: mainTest2
            },
            {
                path: '/main/userList',
                component: userList
            },
            {
                path: '/main/activeList',
                component: activeList
            },
            {
                path: '/main/IndexPic',
                component: IndexPic
            },
            {
                path: '/main/forum',
                component: forum
            },
            {
                path: '/main/notice',
                component: notice
            },
            {
                path: '/main/chat',
                component: chatList
            },
            {
                path: '*',
                component: error
            }
        ]
    },
    {
        path: '*',
        component: error
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if (to.path === '/' || to.path === '/login') {
        return next();
    } else {
        // return next();
        const token = window.sessionStorage.getItem('token');
        if (token!==null) {
            // TODO 404未解决  session的作用域 其他页面打开跳登录
            // if (to.matched.length === 0){
            //     Vue.prototype.$message({
            //         message: '404！嘤嘤嘤！！！',
            //         type: 'danger',
            //         center: true,
            //         showClose: true
            //     });
            //     return next('/error');
            // }
            return next();
        } else {
            Vue.prototype.$message({
                message: '请先登录后在访问！',
                type: 'warning',
                center: true,
                showClose: true
            });
            return next('/')
        }

    }

})


export default router
